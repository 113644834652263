<template>
    <div class="trad">
        <shop></shop>
    </div>
</template>

<script>
import shop from "@/views/data-report/shop";

export default {
	name: "indexT",
	components: {
		shop
	}
}
</script>

<style scoped>
.trad {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
}

.trad > div {
    width: 100%;
    height: 100%;
    display: flex;
}
</style>
